/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
/* Import all of Bootstrap's CSS */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  background-color: #ceede4;
  /* Because we want sticky header */
  height: 6.4rem;
  padding: 0 3.2rem;
  position: relative;
}
.compose-logo {
  display: flex;
  /* padding-top: 1rem; */
}

.compose-logo h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-title-activity {
  padding-top: 1.2rem;
}
.logo-activity {
  line-height: 0.05;
}

.compose-logo {
  /* color: #043224; */
  font-size: 3.8rem;
  line-height: 1.05;
  font-family: "Nova Round", cursive;
  font-weight: 600;
  color: #087f5b;
  letter-spacing: -2px;
}
.header .compose-logo p {
  color: rgba(0, 0, 0, 0.6);
  font-family: cursive;
  font-size: 2rem;
  letter-spacing: 0;

  letter-spacing: -0.5px;
}

.logo {
  padding-top: 0.6rem;
  width: 100%;
}
/* -------------------------------------------------------------------------- */
/*                                  NAVIGATIN                                 */
/* -------------------------------------------------------------------------- */

.main-nav-list {
  list-style: none;
  align-items: center;
  display: flex;
  gap: 4.8rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}
.main-nav-link:hover,
.main-nav-link:active {
  color: #0a8560;
}
.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 2rem;
  color: #ceede4;
  background-color: #0ca678;
}
.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #0a8560;
}
/* MOBILE */
.btn-mobile-nav {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: none;
}
.icon-mobile-nav {
  height: 3.6rem;
  width: 3.6rem;
  color: #333;
}
.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/* STICKY NAVIGATION */
.sticky .header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  background-color: #e7f6f2;
}

.sticky .section-hero {
  margin-top: 8rem;
}
/* -------------------------------------------------------------------------- */
/*                                HERO SECTION                                */
/* -------------------------------------------------------------------------- */
.section-hero {
  background-color: #ceede4;
  padding: 4.8rem 0 9.6rem 0;
}
.hero {
  max-width: 120rem; /* 1300px */
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  /* align-items: center; */
}

.hero-description {
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 4.8rem;
}
/* .hero-img-box {
  display: grid;
} */
.hero-img {
  width: 110%;
  border-radius: 10%;
}

/* -------------------------------------------------------------------------- */
/*                            FEATURED IN SECTION                            */
/* -------------------------------------------------------------------------- */
.section-featured {
  padding: 4.8rem 0 3.2 rem 0;
}
.heading-featured-in {
  font-size: 1.4;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  /* text-align: center; */
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
  color: #888;
}
.logos {
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: space-around;
  align-items: center;
}

.logos img {
  height: 3.6rem;
  /* filter: brightness(50); */
  filter: grayscale(75%);
  /* aspect-ratio: 4/ 1.2; */
  /* background-size: cover; */
  /* opacity: 50%; */
}

/* -------------------------------------------------------------------------- */
/*                            WHY US SECTION                            */
/* -------------------------------------------------------------------------- */
/* section-how */

.section-why-us {
  padding: 4.8rem 0;
}

/* FEATURES */
.features-icon {
  stroke: #087f5b;
  width: 32px;
  height: 32px;
  margin-right: 1.2rem;
  /* margin-bottom: 24px; */
}

.features-title {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.features-text {
  font-size: 1.8rem;
  line-height: 1.7;
}

/* -------------------------------------------------------------------------- */
/*                                   Circle                                   */
/* -------------------------------------------------------------------------- */
.quote-wrapper {
  height: 27.2rem;
  width: 27.2rem;
  position: relative;
}
.text {
  background: radial-gradient(
    ellipse at center,
    #ceede4 0%,
    #ceede4 70%,
    rgba(0, 128, 172, 0) 70.3%
  );
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
}
.text p {
  font-size: 2rem;
  font-style: italic;
  height: 100%;
  line-height: 1.25;
  padding: 0;
  text-align: center;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
}
.text::before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(
    0 0,
    98% 0,
    50% 6%,
    23.4% 17.3%,
    6% 32.6%,
    0 50%,
    6% 65.6%,
    23.4% 82.7%,
    50% 94%,
    98% 100%,
    0 100%
  );
  shape-margin: 7%;
}
.text p::before {
  content: "";
  float: right;
  height: 100%;
  width: 50%;
  shape-outside: polygon(
    2% 0%,
    100% 0%,
    100% 100%,
    2% 100%,
    50% 94%,
    76.6% 82.7%,
    94% 65.6%,
    100% 50%,
    94% 32.6%,
    76.6% 17.3%,
    50% 6%
  );
  shape-margin: 7%;
}
/* -------------------------------------------------------------------------- */
/*                                 STEP SECTION                               */
/* -------------------------------------------------------------------------- */

.step-description {
  font-size: 2rem;
  line-height: 1.8;
}
.section-step {
  padding: 6.4rem 0 0 0;
}
.over-head-projects {
  text-align: center;
  font-size: 1.8rem;
}
.step-img {
  width: 100%;
  aspect-ratio: 2 / 1.2;
  border-radius: 10%;
}
.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #ddd;
  margin-bottom: 1.2rem;
}
.step-img-box {
  overflow: hidden;
  border-radius: 10%;
}
.step-img-box img {
  transition: all 0.4s;
  /* background-color: red; */
}
.step-img-box img:hover {
  transform: scale(1.1);
}

/* -------------------------------------------------------------------------- */
/*                                CLIENTS SECTION                                */
/* -------------------------------------------------------------------------- */
.section-clients {
  padding-bottom: 4rem;
}
.clients {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  margin-top: 2rem;
}
.clients-imgs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-around;
  margin-bottom: 2rem;
}
.clients-imgs img {
  height: 8.6rem;
  /* border-radius: 10%; */
  /*margin-right: 0.5rem;
  border: 3px solid #e7f6f2; */
}
.clients-text {
  font-size: 2.4rem;
  font-weight: 600;
  color: 0a8560;
  font-weight: 700;
  color: #043224;
}
.start-order-btns {
  display: flex;
}
/* -------------------------------------------------------------------------- */
/*                                 PRODUCTS SECTION                            */
/* -------------------------------------------------------------------------- */
.section-products {
  padding: 4.8rem 0rem;
}

.product-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 2.4rem;
}
.product {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  overflow: hidden; /* ignore corner of image*/
  transition: all 0.4s;
  background-color: #e7f6f2;
}

.product:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
  /* transform: scale(1.02); */
}
.product-content {
  padding: 1.6rem;
}
.product-specifications {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.product-data {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  color: #043224;
}
.product.grid-row-3-cols {
  display: flex;
  justify-content: space-between;
  padding-left: 4.8rem;
  position: relative;
}
.product-img {
  width: 100%;
}
.product-barrier {
  grid-row: auto / span 2;
}
.grid-row-3-cols {
  grid-column: span 3;
}

.note {
  background-color: #0ca678;
  color: #043224;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 2.8rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;

  display: inline-block;
  position: absolute;
  top: -1rem;
  left: -4rem;
  /* border-radius: 2.4rem; */
  z-index: 1;
  transform: rotate(315deg);
}

.note-description {
  font-size: 1.6rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.note-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.note-title {
  font-size: 2rem;
  color: #555;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
.note-data {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.abs-title {
  font-size: 1.8rem;
  color: #666;
  font-weight: 500;
  margin-bottom: 1.6rem;
}

/* .product.grid-row-3-cols::after {
  content: "Best value";
  position: absolute;
  top: 0;
  right: -5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #0ca678;
  padding: 0.8rem 9.6rem;
  transform: rotate(30deg);
 } */

/* -------------------------------------------------------------------------- */
/*                                 FEATURE SECTION                            */
/* -------------------------------------------------------------------------- */
.feature-icon {
  margin-bottom: 2.4rem;
  background-color: #ceede4;
  padding: 1.2rem;
  border-radius: 50%;
}
.feature-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1.6rem;
}
.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

/* -------------------------------------------------------------------------- */
/*                                 FOOTER SECTION                            */
/* -------------------------------------------------------------------------- */

.page-footer {
  padding: 8.2rem 0;
  border-top: 1px solid #ddd;
}
.grid-footer {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
}

.logo-col {
  flex-direction: column;
}
.footer-logo {
  display: block;
  margin-bottom: 3.2rem;
}
.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.contact {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}
.address {
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
}
.social-links {
  list-style: none;
  display: flex;
  gap: 2.4rem;
}
.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}
.copyright {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #767676;
  margin-top: auto;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #767676;
  transition: all 0.3s;
}
.footer-link:hover,
.footer-link:active {
  color: #333;
}
