.header {
  height: 6.4rem;
  background-color: #ceede4;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.2rem;
  display: flex;
  position: relative;
}

.compose-logo {
  display: flex;
}

.compose-logo h2 {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.logo-title-activity {
  padding-top: 1.2rem;
}

.logo-activity {
  line-height: .05;
}

.compose-logo {
  color: #087f5b;
  letter-spacing: -2px;
  font-family: Nova Round, cursive;
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 1.05;
}

.header .compose-logo p {
  color: #0009;
  letter-spacing: 0;
  letter-spacing: -.5px;
  font-family: cursive;
  font-size: 2rem;
}

.logo {
  width: 100%;
  padding-top: .6rem;
}

.main-nav-list {
  align-items: center;
  gap: 4.8rem;
  list-style: none;
  display: flex;
}

.main-nav-link:link, .main-nav-link:visited {
  color: #333;
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.main-nav-link:hover, .main-nav-link:active {
  color: #0a8560;
}

.main-nav-link.nav-cta:link, .main-nav-link.nav-cta:visited {
  color: #ceede4;
  background-color: #0ca678;
  border-radius: 2rem;
  padding: 1.2rem 2.4rem;
}

.main-nav-link.nav-cta:hover, .main-nav-link.nav-cta:active {
  background-color: #0a8560;
}

.btn-mobile-nav {
  cursor: pointer;
  background-color: #0000;
  border: none;
  display: none;
}

.icon-mobile-nav {
  height: 3.6rem;
  width: 3.6rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.sticky .header {
  width: 100%;
  z-index: 999;
  height: 8rem;
  background-color: #e7f6f2;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  box-shadow: 0 1.2rem 3.2rem #0000001a;
}

.sticky .section-hero {
  margin-top: 8rem;
}

.section-hero {
  background-color: #ceede4;
  padding: 4.8rem 0 9.6rem;
}

.hero {
  max-width: 120rem;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
}

.hero-description {
  margin-bottom: 4.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

.hero-img {
  width: 110%;
  border-radius: 10%;
}

.section-featured {
  padding: 4.8rem 0 3.2 rem 0;
}

.heading-featured-in {
  text-transform: uppercase;
  letter-spacing: .75px;
  color: #888;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  font-size: 1.4px;
  font-weight: 500;
}

.logos {
  grid-template-columns: repeat(6, auto);
  justify-content: space-around;
  align-items: center;
  display: grid;
}

.logos img {
  height: 3.6rem;
  filter: grayscale(75%);
}

.section-why-us {
  padding: 4.8rem 0;
}

.features-icon {
  stroke: #087f5b;
  width: 32px;
  height: 32px;
  margin-right: 1.2rem;
}

.features-title {
  text-align: center;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  display: flex;
}

.features-text {
  font-size: 1.8rem;
  line-height: 1.7;
}

.quote-wrapper {
  height: 27.2rem;
  width: 27.2rem;
  position: relative;
}

.text {
  height: 100%;
  width: 100%;
  background: radial-gradient(#ceede4 0% 70%, #0080ac00 70.3%);
  margin: 0;
  position: relative;
}

.text p {
  height: 100%;
  text-align: center;
  text-shadow: .5px .5px 1px #0000004d;
  padding: 0;
  font-size: 2rem;
  font-style: italic;
  line-height: 1.25;
}

.text:before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(0 0, 98% 0, 50% 6%, 23.4% 17.3%, 6% 32.6%, 0 50%, 6% 65.6%, 23.4% 82.7%, 50% 94%, 98% 100%, 0 100%);
  shape-margin: 7%;
}

.text p:before {
  content: "";
  float: right;
  height: 100%;
  width: 50%;
  shape-outside: polygon(2% 0%, 100% 0%, 100% 100%, 2% 100%, 50% 94%, 76.6% 82.7%, 94% 65.6%, 100% 50%, 94% 32.6%, 76.6% 17.3%, 50% 6%);
  shape-margin: 7%;
}

.step-description {
  font-size: 2rem;
  line-height: 1.8;
}

.section-step {
  padding: 6.4rem 0 0;
}

.over-head-projects {
  text-align: center;
  font-size: 1.8rem;
}

.step-img {
  width: 100%;
  aspect-ratio: 2 / 1.2;
  border-radius: 10%;
}

.step-number {
  color: #ddd;
  margin-bottom: 1.2rem;
  font-size: 8.6rem;
  font-weight: 600;
}

.step-img-box {
  border-radius: 10%;
  overflow: hidden;
}

.step-img-box img {
  transition: all .4s;
}

.step-img-box img:hover {
  transform: scale(1.1);
}

.section-clients {
  padding-bottom: 4rem;
}

.clients {
  align-items: center;
  column-gap: 1.2rem;
  margin-top: 2rem;
  display: flex;
}

.clients-imgs {
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-around;
  margin-bottom: 2rem;
  display: grid;
}

.clients-imgs img {
  height: 8.6rem;
}

.clients-text {
  color: 0a8560;
  color: #043224;
  font-size: 2.4rem;
  font-weight: 700;
}

.start-order-btns {
  display: flex;
}

.section-products {
  padding: 4.8rem 0;
}

.product-title {
  color: #333;
  margin-bottom: 2.4rem;
  font-size: 2.4rem;
  font-weight: 600;
}

.product {
  background-color: #e7f6f2;
  border-radius: 11px;
  transition: all .4s;
  overflow: hidden;
  box-shadow: 0 2.4rem 4.8rem #00000013;
}

.product:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem #0000000f;
}

.product-content {
  padding: 1.6rem;
}

.product-specifications {
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.product-data {
  color: #043224;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.6rem;
  display: flex;
}

.product.grid-row-3-cols {
  justify-content: space-between;
  padding-left: 4.8rem;
  display: flex;
  position: relative;
}

.product-img {
  width: 100%;
}

.product-barrier {
  grid-row: auto / span 2;
}

.grid-row-3-cols {
  grid-column: span 3;
}

.note {
  color: #043224;
  text-transform: uppercase;
  z-index: 1;
  background-color: #0ca678;
  padding: 2.8rem 4rem 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: -4rem;
  transform: rotate(315deg);
}

.note-description {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.6rem;
}

.note-list {
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  display: flex;
}

.note-title {
  color: #555;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 600;
}

.note-data {
  align-items: center;
  gap: 2.4rem;
  font-size: 1.6rem;
  display: flex;
}

.abs-title {
  color: #666;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.feature-icon {
  background-color: #ceede4;
  border-radius: 50%;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
}

.feature-title {
  color: #333;
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  font-weight: 700;
}

.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

.page-footer {
  border-top: 1px solid #ddd;
  padding: 8.2rem 0;
}

.grid-footer {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
}

.logo-col {
  flex-direction: column;
}

.footer-logo {
  margin-bottom: 3.2rem;
  display: block;
}

.footer-heading {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.contact {
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.6;
}

.address {
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
}

.social-links {
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  color: #767676;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.footer-nav {
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.footer-link:link, .footer-link:visited {
  color: #767676;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .3s;
}

.footer-link:hover, .footer-link:active {
  color: #333;
}

/*# sourceMappingURL=index.7bff721f.css.map */
